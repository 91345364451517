import React, {useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as regionsActions from '../../actions/mobileTowers/regionsActions';
import CompanySelection from "../Common/Selection/CompanySelection";
import RegionLeaf from "./RegionLeaf";
import EmptyLoader from "../Common/EmptyLoader";
import {FaPlus} from "react-icons/fa";

function RegionsTreeView({ actions, regions, toggleCreateModal, currentUser }) {
    const [regionsRequested, setRegionsRequested] = useState(false);
    const [companyId, setCompanyId] = useState(0);

    // console.log('regions', regions);
    useEffect(() => {
        if (!regionsRequested && !regions.loaded) {
            actions.getRegions();
            setRegionsRequested(true);
        }
    }, []);

    if (regions.loading) {
        return (
            <EmptyLoader
                paddingTop={0}
                color={'zinc-400'}
                message={'Loading Regions'}
            />
        );
    }

    const topRegions = regions.finalHierarchy["0"];
    // TODO Handle multiple top places
    const topPlaceId = (topRegions && topRegions.length) > 0 ? topRegions[0] : 0;

    const regionBase = (
        <RegionLeaf
            key={`place-0`}
            placeId={topPlaceId}
            shouldExpand={true}
            level={0}
        />
    );

    const companySelect = (
        <CompanySelection
            value={companyId}
            onChange={(event) => {
                const newId = parseInt(event.target.value);
                setCompanyId(newId);
                actions.onCompanyIdChanged(newId);
            }}
        />
    );

    let btnNewTower = null;

    const newTowerRoles = ['admin', 'vendor', 'circle_lead', 'senior_designer'];
    const isNewTowerAllowed = (currentUser.userData.role && (newTowerRoles.indexOf(currentUser.userData.role.shortCode) !== -1));

    if (isNewTowerAllowed) {
        btnNewTower = (
            <button className="btn btn-primary btn-xs" onClick={toggleCreateModal}>
                <FaPlus className="w-3 h-3 inline" />
                NEW TOWER
            </button>
        );
    }

    return (
        <div className="regions-tree-view">
            <div className="flex justify-between">
                <p className="text-sm">Regions</p>
                {btnNewTower}
            </div>

            <div className="mt-2 leading-relaxed">
                <div className="mb-2">
                    {companySelect}
                </div>

                {regionBase}
            </div>
        </div>
    );
}

RegionsTreeView.defaultProps = {
    actions: {},
    regions: {},
};

RegionsTreeView.propTypes = {
    actions: PropTypes.object,
    regions: PropTypes.object,
};

const mapStateToProps = state => ({
    regions: state.regions,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, regionsActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionsTreeView);

